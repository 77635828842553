import React from 'react'
import styled from 'styled-components'
import styles from './styles'
import { Link } from 'gatsby'

const ElementLink = styled(Link)`${styles}`
const Element = styled.div`${styles}`
const Logo = ({ link }) => {
  if (link) {
    return (
      <ElementLink to={link}>Psírehabilitace.cz</ElementLink>
    )
  }
  else {
    return (
      <Element>Psírehabilitace.cz</Element>
    )
  }
}

Logo.defaultProps = {
  link: false,
}

export default Logo
