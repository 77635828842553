import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #f7f7f7;
  }
  .rehab-container {
    @media(max-width: 991px) {
      max-width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    @media(max-width: 767px) {
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  @media(max-width: 767px) {
    h1 {
      font-size: 42px !important;
    }
    h2 {
      font-size: 34px !important;
    }
    h3 {
      font-size: 24px !important;
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
    }
  }
`;
 
export default GlobalStyle;