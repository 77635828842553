const useThemeColors = () => {
  return {
    purple: '#544bad',
    blue: '#4c95c2',
    green: '#249da4',
    dark: '#3c3c3c',
    lightGrey: '#f6f2e7',
    grey: '#eeeade',
  }
}

export default useThemeColors