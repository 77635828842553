import React from 'react'
import styled from 'styled-components'
import styles from './styles'
import { Container, Row  } from 'styled-bootstrap-grid'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// hooks
import { useThemeColors } from '@Hooks'

// elements
import Logo from '@Elements/logo'

const Section = styled.footer`${styles}`
const Footer = () => {
  const colors = useThemeColors()
  const image = useStaticQuery(graphql`
    query {
      arnostek: file(relativePath: { eq: "arnost-head.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 600, 
            grayscale: true,
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Section colors={colors}>
      <Container className="rehab-container">
        <Row>
            <div className="footer-logo-container">
              <Img
                className="arnostek-photo"
                fluid={image.arnostek.childImageSharp.fluid} 
                alt="Arnoštek"
              />
              <Logo />
            </div>
        </Row>
      </Container>
    </Section>
  )
}

export default Footer