import styled, { css } from 'styled-components'

export default css`
  width: 100%;
  height: 580px;
  background: linear-gradient(to right, rgb(0, 180, 219), rgb(0, 131, 176));
  background: linear-gradient(60deg,#562db5,#19afc9);
  border-bottom-right-radius: 113vw 35vw;
  border-bottom-left-radius: 113vw 35vw;
  position: relative;
  overflow: hidden;

  @media(max-width: 1200px) {
    height: 420px;
  }

  .gatsby-image-wrapper {
    height: 580px;
    @media(max-width: 1200px) {
      height: 420px;
    }
  }

  .header-content {
    margin-top: 100px;
    color: white;
    text-align: right;
    .info-container {
      // border: 1px dashed white;
    }
    .phone-info {
      font-size: 48px;
      font-weight: 800;
      opacity: 0.7;
      svg {
        margin-right: 12px;
      }
    }
    .address {
      opacity: 0.7;
      font-size: 28px;
      span {
        position: relative;
        top: -14px;
      }
      svg {
        font-size: 48px;
        margin-right: 12px
      }
    }
    @media(max-width: 1200px) {
      position: absolute;
      right: 0;

      .phone-info {
        font-size: 32px;
        svg {
          font-size: 32px;
        }
      }

      .address {
        font-size: 18px;
        svg {
          font-size: 32px;
          margin-right: 24px;
        }
      }
    }

    @media(max-width: 610px) {
      margin-top: 50px;
      .phone-info {
        font-size: 24px;
        svg {
          font-size: 24px;
        }
      }
      .address {
        font-size: 14px;
        svg {
          font-size: 24px;
          margin-right: 14px;
        }
        span {
          top: -8px;
        }
      }
    }

  }
`

export const TopContainer = styled.div`
  position: relative;
  z-index: 100;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media(max-width: 1200px) {
    width: 100%;
  }

  padding-top: 50px;
  display: flex;
  justify-content: space-between;
` 
