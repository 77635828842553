import { css } from 'styled-components'

export default css`
  background: transparent;
  text-decoration: none;
  font-family: 'Kalam',cursive;
  font-size: 42px;
  font-weight: 300;
  color: white;
  opacity: 0.8;
  line-height: 0.5;
  transition: all 500ms ease-out 0.1s;

  &:hover {
    opacity: 1;
    transition: all 500ms ease-out 0.1s;
  }

  @media(max-width: 767px) {
    font-size: 24px;
  }
`