import { css } from 'styled-components'

export default css`
  padding: 25px 0;
  background: ${props => props.colors.dark};
  background: linear-gradient(135deg, #383838, ${props => props.colors.dark});

  .arnostek-photo {
    width: 280px;
    max-width: 50px;
    opacity: 0.5;
    margin-right: 10px;
  }

  .footer-logo-container {
    display: flex;
    align-items: center;
    & > div {
      font-size: 28px !important;
    }
  }
`