import React from 'react'
import styled from 'styled-components'
import styles, { TopContainer } from './styles'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Logo from '@Elements/logo'
//import Navigation from '@Blocks/navigation'

// assets
// import { MdAlternateEmail } from 'react-icons/md'
// import { FaPhone } from 'react-icons/fa'

const Section = styled.section`${styles}`
const Header = ({ imageAlt, link, headerContent }) => {
  const image = useStaticQuery(graphql`
    query {
      main: file(relativePath: { eq: "foto1.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1200, 
            grayscale: false,
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Section>
      <TopContainer>
        <Logo link={link} /> 
      </TopContainer>
      {headerContent}
      <Img
        fluid={image.main.childImageSharp.fluid}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          opacity: '0.2',
          // height: '580px',
        }}
        alt={imageAlt}
      />
    </Section>
  )
} 

Header.defaultProps = {
  imageAlt: 'obrázek',
  link: false,
}

export default Header
