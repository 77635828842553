import React from 'react'
import { Layout } from './styles'
import GlobalStyle from './globalStyles'

// sections
import Header from '@Sections/header'
import HeaderStoryDetail from '@Sections/header-story-detail'
import Footer from '@Sections/footer'

const RootLayout = ({ mainImage, link, headerType, headerContent, children }) => (
  <>
    <GlobalStyle />
    <Layout>
      {headerType === 'default' && (
        <Header
          image={mainImage ? mainImage : undefined}
          link={link}
          headerContent={headerContent}
        />
      )}
      {headerType === 'story-detail' && (
        <HeaderStoryDetail
          image={mainImage ? mainImage : undefined}
          link={link}
          headerContent={headerContent}
        />
      )}
      {children}
      <Footer />
    </Layout>
  </>
)

RootLayout.defaultProps = {
  link: '/',
  headerContent: false,
  headerType: 'default'
}

export default RootLayout
